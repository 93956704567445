<template>
  <div v-if="mode == 'header'" class="text-center">
    <div class="p-1 profile-image-wrapper-header">
      <img
        v-show="!loading"
        ref="profileImageRef"
        :src="imageToShow"
        class="profile-image-header"
        alt="Profile Image"
      />

      <div v-if="loading" class="">
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div v-if="mode == 'list'" class="text-center">
    <div class="p-1 profile-image-wrapper-list">
      <img
        v-show="!loading"
        ref="profileImageRef"
        :src="imageToShow"
        class="profile-image-list"
        alt="Profile Image"
      />

      <div v-if="loading" class="">
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div v-if="mode == 'profile'" class="text-center">
    <template v-if="isEditable">
      <label
        @mouseover="showOverlay"
        @mouseout="hideOverlay"
        class="mb-0 pb-0"
        for="imageFiled"
      >
        <div class="p-1 profile-image-wrapper">
          <img
            v-show="!loading"
            ref="profileImageRef"
            :src="imageToShow"
            class="profile-image mx-auto"
            alt="Profile Image"
          />

          <div v-if="loading" class="">
            <div class="spinner-grow text-secondary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div
            v-show="!loading"
            class="profile-image-overlay d-flex align-items-center justify-content-center"
          >
            <div>
              Upload Image
              <div class="small-2">(Max size: 512KB)</div>
            </div>
          </div>
        </div>
      </label>

      <input
        type="file"
        id="imageFiled"
        name="file"
        accept="image/*"
        hidden="true"
        ref="fileInputRef"
        @change="handleFileChange"
      />
      <div
        v-if="fileSize > 0"
        :class="`${fileSize > 512 ? 'text-danger' : 'text-muted'}`"
        class="small"
      >
        File size: {{ fileSize }}kb
      </div>
      <button
        :disabled="loading"
        @click.prevent="save"
        v-if="previewImage"
        class="btn btn-sm btn-primary mx-2 mt-2"
      >
        Save
      </button>
      <button
        :disabled="loading"
        v-if="previewImage"
        @click.prevent="cancel"
        class="btn btn-sm btn-secondary mx-2 mt-2"
      >
        Cancel
      </button>
    </template>

    <div v-else class="p-1 profile-image-wrapper">
      <img
        v-show="!loading"
        ref="profileImageRef"
        :src="imageToShow"
        class="profile-image mx-auto cursor-default"
        alt="Profile Image"
      />

      <div v-if="loading" class="">
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import { notify } from "@/services/helpers";
import axios from "axios";
import AppConsts from "@/shared/application/work-force";
import AuthStore from "@/store/auth-store";
import { useStore } from "vuex";

export default {
  name: "ProfileImage",
  props: {
    image: {
      type: String,
      required: true,
    },
    employeeId: {
      type: Number,
      required: true,
    },

    gender: {},
    mode: {
      type: String,
      default: "profile",
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const currentImage = ref(null);
    const previewImage = ref(null);
    const displayOverlay = ref(false);
    const loading = ref(false);
    const fileInputRef = ref();
    const store = useStore();
    const fileSize = ref(0);

    const fallBackImage = () => {
      if (typeof props.gender == "undefined") {
        currentImage.value = require("@/assets/avatar.png");
      } else if (props.gender == "0") {
        currentImage.value = require("@/assets/avatar.png");
      } else if (props.gender == "1") {
        currentImage.value = require("@/assets/avatar_female.png");
      } else {
        currentImage.value = require("@/assets/avatar.png");
      }
    };

    watch(
      () => props.image,
      () => {
        // Update the reactive variable when the prop changes
        fetchImage(props.image, true);
      }
    );

    const workForceService = new WorkForceService();

    onMounted(() => {
      initFetchImg();
    });

    onBeforeUnmount(() => {
      URL.revokeObjectURL(currentImage.value);
    });

    const fetchImage = (image, updateCache = false) => {
      loading.value = true;
      axios
        .get(
          AppConsts.baseApiUrl +
            "/employee/GetProfilePicture/" +
            props.employeeId,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${AuthStore.getToken()}`,
              "Bypass-Cache": updateCache.toString(),
            },
          }
        )
        .then((response) => {
          loading.value = false;

          if (response.status === 200) {
            let filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(".")[0];
            let extension = response.headers["content-disposition"]
              .split(".")[1]
              .split(";")[0];
            const cacheImage = `${filename}.${extension}`;

            // If the image was updated the make a fresh call to api instead loading the cache
            if (cacheImage != image) {
              fetchImage(props.image, true);
            } else {
              let reader = new FileReader();
              reader.readAsDataURL(response.data);
              reader.onload = () => {
                currentImage.value = reader.result;
              };
            }
          } else {
            fallBackImage();
          }
        })
        .catch((error) => {
          loading.value = false;

          fallBackImage();
        });
    };

    const initFetchImg = async () => {
      // props.image
      if (props.image) {
        fetchImage(props.image);
      } else {
        fallBackImage();
      }
    };

    const save = () => {
      if (previewImage.value) {
        loading.value = true;
        const formData = new FormData();
        formData.append("file", fileInputRef.value.files[0]);

        workForceService
          .postMulitPart(`/employee/UploadProfilePicture`, formData, true)
          .then((response) => {
            loading.value = false;
            if (!response.isError) {
              notify(
                "Profile picture uploaded successfully.",
                "Success",
                "success"
              );
              currentImage.value = previewImage.value;
              previewImage.value = null;
              store.commit("authUser", response.content);
              fetchImage(response.content.profileImage, true);
            } else {
              notify(response.errors, "Error", "danger");
              fallBackImage();
            }
          });
      }
    };

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          previewImage.value = reader.result;
        };
        reader.readAsDataURL(file);
        fileSize.value = Math.round(file.size / 1024);
      } else {
        previewImage.value = null;
      }
    };

    const showOverlay = () => {
      displayOverlay.value = true;
    };

    const hideOverlay = () => {
      displayOverlay.value = false;
    };

    const imageToShow = computed(() => {
      return previewImage.value || currentImage.value;
    });

    const cancel = () => {
      fileSize.value = 0;
      previewImage.value = null;
      fetchImage(props.image);
    };

    return {
      imageToShow,
      handleFileChange,
      fileInputRef,
      showOverlay,
      hideOverlay,
      displayOverlay,
      previewImage,
      cancel,
      save,
      loading,
      fileSize,
    };
  },
};
</script>

<style>
.profile-image-container {
  text-align: center;
}

.image-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.hidden-input {
  display: none;
}

.upload-button {
  cursor: pointer;
  margin-top: 8px;
}
</style>
