
import { defineComponent } from "vue";
import store from "@/store";
import { parseQuery, stringifyQuery } from "vue-router";
type filter = {
  key: string;
  keyLabel: string;
  dataType: string;
  dropDownOptions: [];
};

export default defineComponent({
  name: "Filter",
  props: {
    filtersProp: {
      type: Array,
    },
    sortProp: {
      type: Array as any,
    },
    content: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["clear-filter", "apply-filter", "apply-sort"],
  data() {
    return {
      maxFilter: 7,
      sortBy: {
        key: "",
        value: "",
      },
      sortOrder: "asc",
      status: true,
    };
  },
  created() {
    this.sortBy.key = this.sortProp ? this.sortProp[0].key : "";
    this.sortBy.value = this.sortProp ? this.sortProp[0].value : "";
    if (this.query) {
      this.event_applyFilter();
    }
    if (
      Object.keys(this.$route.query).length !== 0 &&
      (this.filtersProp as filter[]).length > 0
    ) {
      store.dispatch(
        "preAddFilters",
        this.generateQueryObject(stringifyQuery(this.$route.query))
      );
      this.event_applyFilter();
    }
  },

  unmounted() {
    this.resetFilter();
  },

  computed: {
    filters() {
      return store.getters.filters;
    },

    filteredFilters() {
      const propFilters = this.filtersProp;
      const currentSelectedFilters = this.filters;

      propFilters?.forEach((filter: any) => {
        filter["disabled"] = false;
        currentSelectedFilters.forEach((selected: any) => {
          if (filter.key == selected.properties?.key) {
            filter["disabled"] = true;
          }
        });
      });

      return propFilters;
    },

    query() {
      return store.getters.query;
    },
  },
  methods: {
    addFilter() {
      store.dispatch("addFilters");
    },

    removeFilter(index: string | number) {
      store.dispatch("removeFilter", index);
    },

    generateQueryObject(queryString: string) {
      const queryArray = queryString.split("&");
      const queryObjects = [];

      for (const query of queryArray) {
        const [key, value] = query.split("=");

        if (key) {
          const dropDownOptions = this.getDropDownOptions(key);

          queryObjects.push({
            properties: {
              key: key,
              dataType: dropDownOptions ? "dropDowns" : "string",
              dropDownOptions: dropDownOptions,
            },
            query: value,
          });
        }
      }

      return queryObjects;
    },
    getDropDownOptions(key: string) {
      const object = this.filtersProp?.find((item: any) => item.key == key)
        ? (this.filtersProp.find((item: any) => item.key == key) as filter)
            .dropDownOptions
        : [];

      switch (key) {
        case "DepartmentId":
          return object;
        case "IsActive":
          return object;
        case "IsVerified":
          return object;
        case "ManagerId":
          return object;
        case "Role":
          return object;
        default:
          return undefined;
      }
    },

    clearFilter() {
      store.dispatch("clearFilters");
      this.$router.push({ name: this.$route.name as any });
      this.$emit("clear-filter");
    },

    resetFilter() {
      store.dispatch("clearFilters");

      this.sortBy.key = this.sortProp ? this.sortProp[0].key : "";
      this.sortBy.value = this.sortProp ? this.sortProp[0].value : "";
      this.sortOrder = "asc";
      this.$router.push({ name: this.$route.name as any });
    },

    event_applyFilter() {
      store.commit("generateQuery");
      this.$router.push({
        name: this.$route.name as any,
        query: parseQuery(this.query),
      });
      this.$emit("apply-filter", this.query);
    },

    event_applySort() {
      let query = "";

      if (this.sortBy) {
        query = this.sortBy.value + "&SortOrder=" + this.sortOrder;
      }
      this.$emit("apply-sort", query);
    },

    changeSortOrder() {
      let query = "";
      if (this.sortOrder == "asc") {
        this.sortOrder = "desc";
      } else {
        this.sortOrder = "asc";
      }
      if (this.sortBy) {
        query = this.sortBy.value + "&SortOrder=" + this.sortOrder;
      }
      this.$emit("apply-sort", query);
    },
  },
});
