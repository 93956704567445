
import { Options, Vue } from "vue-class-component";
import { IEmployee } from "@/types/Employee";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { IEmployeeList } from "@/types/EmployeeList";
import PaginationComponent from "@/components/PaginationComponent.vue";
import { IDepartment } from "@/types/Department";
import Filter from "@/components/Filter.vue";
import AuthStore from "@/store/auth-store";
import { tr } from "date-fns/locale";
import ProfileImage from "@/views/employees/ProfileImage.vue";

@Options({
  name: "Directory",
  components: {
    ProfileImage,
    Breadcrumb,
    PaginationComponent,
    Filter,
  },
})
export default class Directory extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  employees: IEmployee[] = [];
  page = 0;
  perPage = 15;
  totalCount = 0;
  totalFilterCount = 0;
  crumbs: any = ["Dashboard", "Finder"];
  filterData = [
    {
      key: "FirstName",
      keyLabel: "First Name",
      dataType: "string",
    },
    {
      key: "LastName",
      keyLabel: "Last Name",
      dataType: "string",
    },
    {
      key: "Email",
      keyLabel: "Email",
      dataType: "string",
    },
    {
      key: "Role",
      keyLabel: "Role",
      dataType: "dropDowns",
      dropDownOptions: [
        {
          value: "Employee",
          label: "Employee",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Admin",
          label: "Admin",
        },
        {
          value: "Org Admin",
          label: "Organization Admin",
        },
      ],
    },
    {
      key: "IsActive",
      keyLabel: "Is Active",
      dataType: "dropDowns",
      dropDownOptions: [
        {
          value: true,
          label: "Active",
        },
        {
          value: false,
          label: "Not Active",
        },
      ],
    },
  ];
  roles = AuthStore.getTokenData().Roles;

  // For Pagination
  savedPaginationQuery = "";
  get totalPages() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  hasProfileImage(employee: any) {
    if (employee.profileImage) {
      return employee.profileImage;
    }
    return "";
  }

  goToPage(page: number) {
    this.savedPaginationQuery = `PageIndex=${page}&PageSize=${this.perPage}`;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/employee?IsVerified=true&IsActive=true&${
          this.savedFilterQuery ? this.savedFilterQuery : ""
        }PageIndex=${page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        return (this.employees = response.content.items);
      });
  }

  // For Filter
  filtered = false;
  savedFilterQuery = null;
  async filter(query: any) {
    this.savedFilterQuery = query;
    this.filtered = false;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/employee?IsVerified=true&IsActive=true&${query}`,
        false
      )
      .then((response: any) => {
        this.employees = response.content.items;
        this.totalFilterCount = response.content.totalCount;
        this.totalCount = response.content.totalCount;
        this.filtered = true;
      });
  }

  nextPage(page: number) {
    page++;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/employee?IsVerified=true&IsActive=true&PageIndex=${page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        return (this.employees = response.content.items);
      });
  }

  prevPage(page: number) {
    page--;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/employee?IsVerified=true&IsActive=true&PageIndex=${page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        return (this.employees = response.content.items);
      });
  }

  // End Pagination
  checkKey(arrObj: any, value: string) {
    return arrObj.some((obj: { key: string }) => obj.key === value);
  }
  initLoading = false;
  async initialize() {
    this.savedFilterQuery = null;
    await this.workForceService
      .get<IEmployee[]>(
        `/employee?IsVerified=true&IsActive=true&${
          this.savedFilterQuery ? this.savedFilterQuery : ""
        }${
          this.savedPaginationQuery
            ? this.savedPaginationQuery
            : "PageIndex=0&PageSize=" + this.perPage
        }`,
        false
      )
      .then((response: any) => {
        this.totalCount = response.content.totalCount;
        this.employees = response.content.items;
      });

    if (!this.checkKey(this.filterData, "DepartmentId")) {
      await this.workForceService
        .get<IDepartment[]>(`/departments`, false)
        .then((response: any) => {
          const department = {
            key: "DepartmentId",
            keyLabel: "Department",
            dataType: "dropDowns",
            dropDownOptions: [
              {
                value: "",
                label: "",
              },
            ],
          };
          response.content.items.forEach((item: { id: any; name: any }) => {
            const data = {
              value: item.id,
              label: item.name,
            };
            department.dropDownOptions.push(data);
          });
          this.filterData.push(department);
        });
    }

    if (!this.checkKey(this.filterData, "ManagerId")) {
      await this.workForceService
        .get<IDepartment[]>(`/Employee/Managers`, false)
        .then((response: any) => {
          const manager = {
            key: "ManagerId",
            keyLabel: "Reports To",
            dataType: "dropDowns",
            dropDownOptions: [
              {
                value: "",
                label: "",
              },
            ],
          };
          response.content.forEach(
            (item: { id: any; firstName: string; lastName: string }) => {
              const data = {
                value: item.id,
                label: item.firstName + item.lastName,
              };
              manager.dropDownOptions.push(data);
            }
          );
          this.filterData.push(manager);
        });
    }
  }
  async created() {
    this.initLoading = true;
    await this.initialize();
    this.initLoading = false;
  }
}
