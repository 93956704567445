import moment from "moment";
import store from "@/store";
import { array } from "yup";

const formatDate_h = (date: any) => {
  const nDate = new Date(date);
  const year = nDate.getFullYear();
  const month = nDate.getMonth() + 1;
  const day = nDate.getDate();
  return (
    year +
    "-" +
    (month.toString().length < 2 ? "0" + month : month) +
    "-" +
    (day.toString().length < 2 ? "0" + day : day)
  );
};

const useFormatDate = (date: any) => {
  return moment(new Date(date)).format("Do-MMM-YY");
};

const notify = (
  msg: any,
  title = "Attention",
  type = "primary",
  autoHide = true
) => {
  if (typeof msg === "object" && msg !== null) {
    let messages = "";
    for (const key in msg.errors) {
      messages += "<b>" + key + "</b>" + ": " + msg.errors[key][0] + "<br>";
    }
    store.dispatch("showToast", {
      title: title,
      message: messages,
      type: type,
      autoHide,
    });
  } else if (typeof msg == "string") {
    store.dispatch("showToast", {
      title: title,
      message: msg,
      type: type,
      autoHide,
    });
  }
};

const sanitizeHTML = (
  html: any,
  allowedTags = ["div", "br", "b", "p", "strong", "li", "ul", "strike", "a"]
) => {
  // Create a temporary container element
  const container = document.createElement("div");
  container.innerHTML = html;

  // Get all elements in the container
  const elements = container.querySelectorAll("*");

  // Iterate through all elements
  for (const element of elements) {
    // If the element's tag is not allowed, remove it from the DOM
    if (!allowedTags.includes(element.tagName.toLowerCase())) {
      element.parentNode?.removeChild(element);
    }
  }

  // Return the sanitized HTML
  return container.innerHTML;
};

const hourDiff = (start: any, end: any, withText = false) => {
  if (start == "" || end == "" || !start || !end) {
    return "0";
  }
  start = start.split(":");
  end = end.split(":");
  const startDate = new Date(0, 0, 0, start[0], start[1], 0);
  const endDate = new Date(0, 0, 0, end[0], end[1], 0);
  let diff = endDate.getTime() - startDate.getTime();
  let hours = Math.floor(diff / 1000 / 60 / 60);
  diff -= hours * 1000 * 60 * 60;
  const minutes = Math.floor(diff / 1000 / 60);

  // If using time pickers with 24 hours format, add the below line get exact hours
  if (hours < 0) hours = hours + 24;

  if (withText) {
    return `${hours <= 9 ? "0" : ""}${hours}:${
      minutes <= 9 ? "0" : ""
    }${minutes} Hour${hours > 1 ? "s" : ""}`;
  }

  return (
    (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes
  );
};

export { formatDate_h, useFormatDate, notify, sanitizeHTML, hourDiff };
